import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import React, { useState, useEffect, useCallback } from 'react';
import styled from "styled-components";
import { useMediaQuery } from 'react-responsive';

import Navigation from "./components/Navigation";
import LandingPage from "./components/LandingPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookieConsentBanner from "./components/sections/CookieConsentBanner";
import Footer from "./components/sections/Footer";
import { ALLOWED_PATHS } from "./config";

function App() {
  const getInitialLocation = () => {
    const path = window.location.pathname.replace('/', '');
    if (path == '') return 'landing';
    if (ALLOWED_PATHS.includes(path)) return path;
    window.location = '/';
  };
  
  
  const [location, setLocation] = useState(getInitialLocation);
  const [showCookieBanner, setShowCookieBanner] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  
  const update_location = useCallback((newLocation) => {
    let urlDestination = newLocation == 'landing' ? '/' : newLocation;
    setLocation(newLocation);
    window.history.pushState(null, '', `${urlDestination}`);
  }, []);

  useEffect(() => {
    // This will run every time location changes.
    if (location === "privacy_policy" || location === "terms_and_conditions") {
      // Since we're unsure if the DOM has finished rendering the component, 
      // let's give it some time using setTimeout.
      setTimeout(() => {
        const element = document.getElementById(location);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);  // Giving it a 100ms delay to ensure component renders.
    }
  }, [location]);

  useEffect(() => {
    const handlePopState = () => {
      const currentHash = window.location.hash.replace('#', '');
      setLocation(currentHash);
    };
  
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleShowBanner = () => {
      setShowCookieBanner(false);
      setTimeout(() => {
        setShowCookieBanner(true);
      }, 100); // Short delay allows smooth re-render
    };
  
    window.addEventListener('showCookieBanner', handleShowBanner);
    return () => {
      window.removeEventListener('showCookieBanner', handleShowBanner);
    };
  }, []);
  

  const MainContainer = styled.div`
    justify-content: center;
    align-items: center;
  `;

  return (
    <>
      <GlobalStyles />
      <MainContainer id="mainContainer">
        <ThemeProvider theme={dark} id={location}>
          <Navigation update_location={update_location} location={location} />
          {
            location === 'landing' ?
              <LandingPage update_location={update_location} location={location} />
            :
            location === 'privacy_policy' ?
              <PrivacyPolicy />
            :
            <></>
          }

          <Footer update_location={update_location}/>
          {/* Cookie Consent Banner appears at bottom, triggers analytics if accepted */}
          {
            showCookieBanner && <CookieConsentBanner onPrivacyLinkClick={() => update_location("privacy_policy")} />
          }
        </ThemeProvider>
      </MainContainer>
    </>
  );
}

export default App;
