import React from 'react';
import styled, { useTheme } from 'styled-components';
import { PRIVACY_POLICY_DATA } from '../config';
import Footer from './sections/Footer';

const Container = styled.div`
  padding: 3rem 2rem;
  max-width: 800px;
  margin: auto;
  color: ${(props) => props.theme.darkGradientBackgroundColor};
  font-family: Montserrat;
  font-size: 16px;
  line-height: 1.7;

  @media (max-width: 360px) {
    font-size: 14px;
    padding: 2rem 1.5rem;
  }
`;

const Title = styled.h1`
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const SectionTitle = styled.h2`
  margin-top: 2.5rem;
  margin-bottom: 0.75rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${(props) => props.theme.darkGradientBackgroundColor};
`;

const Paragraph = styled.p`
  margin-top: 0.8rem;
`;

const Email = styled.span`
  color: ${(props) => props.theme.darkGradientBackgroundColor};
  font-family: Montserrat;
  font-style: italic;
`;

const LastUpdated = styled.p`
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 2rem;
`;

function PrivacyPolicy() {
  const theme = useTheme();

  return (
    <Container id="privacy_policy">
      <Title>Privacy Policy</Title>
      <LastUpdated><strong>Last updated:</strong> March 22, 2025</LastUpdated>

      {PRIVACY_POLICY_DATA.map((section, index) => (
        <div key={index}>
          <SectionTitle>{section.title}</SectionTitle>
          {section.paragraphs.map((para, idx) => (
            <Paragraph key={idx}>
                {para.split('redtentpmdd@gmail.com').map((part, i, arr) => (
                    <React.Fragment key={i}>
                    {part}
                    {i < arr.length - 1 && <Email>redtentpmdd@gmail.com</Email>}
                    </React.Fragment>
                ))}
            </Paragraph>
          ))}
        </div>
      ))}
    </Container>
  );
}

export default PrivacyPolicy;
