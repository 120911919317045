import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTheme, createGlobalStyle } from 'styled-components';
import { CONSENT_COOKIE_NAME } from '../../config';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BannerWrapper = styled.div`
  animation: ${fadeIn} 0.2s ease-in-out;
  z-index: 9999; /* ensure it's always on top */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

// Global fix for banner and button area background
const CookieConsentStyleFix = createGlobalStyle`
  .CookieConsent {
    background-color: ${(props) => props.theme.darkGradientBackgroundColor} !important;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    padding: 32px 24px !important;
    gap: 16px;
  }

  .CookieConsent > div:last-child {
    background-color: ${(props) => props.theme.darkGradientBackgroundColor} !important;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: end;
    width: 100%;
  }

  @media (min-width: 768px) {
    .CookieConsent {
      flex-direction: row;
      align-items: center;
      padding: 32px 48px !important;
    }

    .CookieConsent > div:last-child {
      width: fit-content;
    }
  }
`;

function CookieConsentBanner({ onPrivacyLinkClick }) {
  const theme = useTheme();

  return (
    <>
      <CookieConsentStyleFix />
      <BannerWrapper id='banner-wrapper'>
        <CookieConsent
          location="bottom"
          cookieName={CONSENT_COOKIE_NAME}
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton
          style={{
            padding: '0',
            margin: '0',
            background: theme.darkGradientBackgroundColor,
            fontFamily: "Montserrat",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
          contentStyle={{
            background: 'transparent',
            padding: '0',
            margin: '0',
            color: 'white',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '1.5',
            fontFamily: 'Montserrat',
            textAlign: 'left',
            flex: '1 1 auto',
            boxSizing: 'border-box'
          }}
          
          buttonStyle={{
            color: theme.accent,
            background: "#fff",
            border: `1px solid #fff`,
            fontSize: "14px",
            borderRadius: "4px",
            padding: "10px 16px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            margin: "0",
          }}
          declineButtonStyle={{
            fontSize: "14px",
            background: theme.darkGradientBackgroundColor,
            color: theme.accent,
            border: `1px solid ${theme.accent}`,
            padding: "10px 16px",
            borderRadius: "4px",
            fontFamily: "Montserrat",
            fontWeight: "500",
            margin: "0",
          }}
          expires={150}
          onAccept={() => {
            window.gtag && window.gtag("consent", "update", {
              ad_storage: "denied",
              analytics_storage: "granted",
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              functionality_storage: 'denied',
              security_storage: 'denied',
            });
          }}
          onDecline={() => {
            // 1. Delete all accessible cookies (by name)
            document.cookie.split(';').forEach(cookie => {
              const name = cookie.trim().split('=')[0];
          
              // Delete for default path
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          
              // Delete for current path
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${window.location.pathname};`;
          
              // Delete with domain (only works on production, not localhost)
              const hostname = window.location.hostname;
              const domain =
                hostname.includes('.') && !hostname.includes('localhost')
                  ? `; domain=.${hostname}`
                  : '';
          
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${domain}`;
            });

            // 2. Re-set consent cookie to remember decline
            document.cookie = `${CONSENT_COOKIE_NAME}=false; path=/; max-age=31536000`;

            // 3. Update Consent Mode (even if GA is already blocked)
            if (window.gtag) {
              window.gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                functionality_storage: 'denied',
                security_storage: 'denied',
              });
            }
          }}
        >
          We use cookies only for basic analytics to understand traffic. We don’t use personal tracking or advertising cookies.
          <span
            onClick={onPrivacyLinkClick}
            style={{
              background: 'transparent',
              color: theme.goldenLinkColor,
              fontFamily: 'Montserrat',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: '4px'
            }}
          >
            Learn more
          </span>
        </CookieConsent>
      </BannerWrapper>
    </>
  );
}

export default CookieConsentBanner;
