import React from 'react';
import styled from 'styled-components';
import { FEATURED_IN_DATA } from '../../config'; 

const Section = styled.section`
  position: relative;
  width: 100%;
  background: ${(props) => props.background || 'inherit'};
  margin-bottom: 100px;
  text-align: center;

  @media (max-width: 64em) {
    margin: 20px;
    margin-bottom: 100px;
  }
`;

const Header = styled.h2`
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 32px;
  color: ${(props) => props.color || 'inherit'};

  @media (max-width: 64em) {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (max-width: 64em) {
    flex-direction: column;
    gap: 20px;
  }
`;

const LogoLink = styled.a`
  display: inline-block;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
`;

const Logo = styled.img`
  height: 60px;
  object-fit: contain;
  opacity: 0.8;

  ${LogoLink}:hover & {
    filter: none;
    opacity: 1;
  }

  @media (max-width: 64em) {
    height: 50px;
  }
`;

const FeaturedIn = () => {
  return (
    <Section>
      <Header>Featured In</Header>
      <LogosContainer>
        {FEATURED_IN_DATA.map((item, index) => (
          <LogoLink key={index} href={item.url} target="_blank" rel="noopener noreferrer">
            <Logo src={item.logo} alt={item.name} />
          </LogoLink>
        ))}
      </LogosContainer>
    </Section>
  );
};

export default FeaturedIn;
